<template>
	<div class="container">
		<div class="title">
			<div class="text">更新幻灯图片</div>
		</div>
		<div class="content" v-if="info">
			<div class="row">
				<div class="left">名称</div>
				<div class="right">
					<input type="text" class="input" v-model="info.banner_name" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">中文图片</div>
				<div class="right">
					<div class="upload-img">
						<input class="file" type="file" value="" data-attribute="banner_image" @change="uploadFile" />
						<img class="img" :src="info.banner_image" :onerror="defaultImg">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">维文图片</div>
				<div class="right">
					<div class="upload-img">
						<input class="file" type="file" value="" data-attribute="banner_image_ug"
							@change="uploadFile" />
						<img class="img" :src="info.banner_image_ug" :onerror="defaultImg">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">哈文图片</div>
				<div class="right">
					<div class="upload-img">
						<input class="file" type="file" value="" data-attribute="banner_image_kz"
							@change="uploadFile" />
						<img class="img" :src="info.banner_image_kz" :onerror="defaultImg">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">中文标题</div>
				<div class="right">
					<input type="text" class="input" v-model="info.banner_title" placeholder="选填">
				</div>
			</div>
			<div class="row">
				<div class="left">维文标题</div>
				<div class="right">
					<input type="text" class="input rtl" v-model="info.banner_title_ug" placeholder="选填">
				</div>
			</div>
			<div class="row">
				<div class="left">哈文标题</div>
				<div class="right">
					<input type="text" class="input rtl" v-model="info.banner_title_kz" placeholder="选填">
				</div>
			</div>
			<div class="row">
				<div class="left">跳转路径</div>
				<div class="right">
					<select class="select" v-model="navigateType" @change="onNavigateType">
						<option value="url">输入地址</option>
						<option value="contact">在线客服</option>
						<option value="usedCar">二手车详细页</option>
						<option value="usedCarList">二手车列表</option>
						<option value="merchant">企业商家主页</option>
						<option value="personalMerchant">个人商家主页</option>
						<option value="compulsoryInsurance">强险查询</option>
						<option value="vinQuery">信息查询</option>
						<option value="carEvaluation">汽车估价</option>
						<option value="app">App</option>
					</select>
					<input type="text" class="input" v-if="navigateType=='url'" v-model="info.banner_url"
						placeholder="请输入中文路径（选填)">
					<input type="text" class="input" v-if="navigateType=='url'" v-model="info.banner_url_ug"
						placeholder="请输入维文路径（选填)">
					<input type="text" class="input" v-if="navigateType=='url'" v-model="info.banner_url_kz"
						placeholder="请输入哈文路径（选填)">
					<input type="number" class="input" v-if="navigateType=='usedCar'" v-model="usedCarId"
						placeholder="请输入汽车id">
					<input type="text" class="input" v-if="navigateType=='app'" v-model="info.banner_app_id"
						placeholder="请输入app id">
					<input type="text" class="input" v-if="navigateType=='app'" v-model="info.banner_url"
						placeholder="请输入路径">
					<input type="number" class="input"
						v-if="navigateType == 'merchant' || navigateType == 'personalMerchant'" v-model="merchantId"
						placeholder="请输入商家id">
			
				</div>
			</div>
			<div class="row">
				<div class="left">位置</div>
				<div class="right">
					<select class="select" v-model="info.banner_type">
						<option :value="1">小程序首页</option>
						<option :value="1">其他 2</option>
						<option :value="1">其他 3</option>
						<option :value="1">其他 4</option>
						<option :value="1">其他 5</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="left">排序号</div>
				<div class="right">
					<input type="number" class="input" v-model="info.banner_sort" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">显示</div>
				<div class="right">
					<switcha class="switcha" :on="info.banner_status==1" v-on:changed="changeDisabled" />
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" type="submit" @click="add">更新</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		updateBanner
	} from "@/api/banner.js";
	import {
		uploadFile
	} from "@/api/common";
	import Switcha from "@/components/widgets/Switch.vue";
	export default {
		components: {
			Switcha
		},
		data() {
			return {
				info: null,
				navigateType: "url",
				usedCarId: "",
				merchantId: "",
				toastText: "请正确输入参数",
				defaultImg: "this.src='https://izpak-admin.elghar.cn/assets/img/default.png'",
				isShowToast: false,
			}
		},
		created() {
			if (this.$route.params.info) {
				this.info = JSON.parse(decodeURIComponent(this.$route.params.info));
				this.setNavigateType()
			} else {
				this.showToast("获取数据失败")
			}

		},
		methods: {
			setNavigateType(){
				if(this.info.banner_app_id){
					this.navigateType = 'app'
				}else if(this.info.banner_url){
					if(this.info.banner_url.indexOf('used_car/detail?id=')>0){
						this.navigateType = 'usedCar';
						this.usedCarId = this.info.banner_url.split("used_car/detail?id=")[1]
					}else if(this.info.banner_url.indexOf('/used_car/list')>0){
						this.navigateType = 'usedCarList'
					}else if(this.info.banner_url.indexOf('car_compulsory_insurance/query')>0){
						this.navigateType = 'compulsoryInsurance'
					}
					else if(this.info.banner_url.indexOf('car_evaluation/query')>0){
						this.navigateType = 'carEvaluation'
					}else if(this.info.banner_url.indexOf('merchant/index?merchant_id=')>0){
						this.navigateType = 'merchant';
						this.merchantId = this.info.banner_url.split('merchant/index?merchant_id=')[1]
					}else if(this.info.banner_url.indexOf('personalMerchant/index?personal_id=')>0){
						this.navigateType = 'personalMerchant'
						this.merchantId = this.info.banner_url.split('personalMerchant/index?personal_id=')[1]
					}else if(this.info.banner_url.indexOf('toContact') == 0){
						this.navigateType = 'contact'
					}else if(this.info.banner_url.indexOf('toContact') == 0){
						this.navigateType = 'contact'
					}
				}
			},
			add() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if (this.navigateType == 'usedCar') {
					this.info.banner_url = this.usedCarId ? '/pages/used_car/detail?id=' + this
						.usedCarId : '';
				} else if (this.navigateType == 'usedCarList') {
					this.info.banner_url = '/pages/used_car/list';
				} else if (this.navigateType == 'compulsoryInsurance') {
					this.info.banner_url = '/pages/car_compulsory_insurance/query/query';
				} else if (this.navigateType == 'carEvaluation') {
					this.info.banner_url = '/pages/car_evaluation/query/query';
				} else if (this.navigateType == 'merchant') {
					this.info.banner_url = this.merchantId?'/pages/used_car/merchant/index?merchant_id=' + this.merchantId:'';
				} else if (this.navigateType == 'personalMerchant') {
					this.info.banner_url = this.merchantId?'/pages/used_car/personalMerchant/index?personal_id=' + this.merchantId:'';
				} else if (this.navigateType == 'contact') {
					this.info.banner_url = 'toContact';
				}
				
				if (this.navigateType != 'url' && this.navigateType != 'app') {
					this.info.banner_url_ug = this.info.banner_url;
					this.info.banner_url_kz = this.info.banner_url;
				}
				
				if (!this.info.banner_name) {
					this.showToast("请输入名称");
					return;
				}
				if (!this.info.banner_image) {
					this.showToast("请输入上传中文图片");
					return;
				}
				if (!this.info.banner_image_ug) {
					this.showToast("请输入上传维文图片");
					return;
				}
				if (!this.info.banner_image_kz) {
					this.showToast("请输入上传哈文图片");
					return;
				}
				this.info.banner_sort *= 1;
				
				isLoad = true;
				updateBanner(this.info).then(res => {
					if (res.status == 200) {
						this.showToast(res.data)
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				}).catch(() => {
					isLoad = false;
					this.showToast("出错了！")
				})

			},
			uploadFile(event) {
				let self = this;
				let file = event.target.files[0];
				let attribute = event.target.dataset.attribute;
				if (file) {
					//创建用来读取此文件的对象
					let reader = new FileReader();
					//使用该对象读取file文件
					reader.readAsDataURL(file);
					//读取文件成功后执行的方法函数
					reader.onload = function() {
						this.formData = new FormData();
						this.formData.append("image", file, file.name);
						//读取成功后返回的一个参数e，整个的一个进度事件
						uploadFile(this.formData).then((res) => {
							if (res.status == 200) {
								self.info[attribute] = res.data.url;
								self.showToast("上传成功");
							} else {
								self.showToast("失败：" + res.message);
							}
			
						});
					};
				}
			},
			onNavigateType() {
				this.info.banner_url = "";
				this.info.banner_url_ug = "";
				this.info.banner_url_kz = "";
				this.usedCarId = "";
				this.merchantId = "";
				this.info.banner_app_id = ""
			},
			changeDisabled() {
				this.info.banner_status = this.info.banner_status == 1 ? 0 : 1
			},
			cancel() {
				this.$router.back()
			},
			inputChange(e){
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>